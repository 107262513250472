import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';
import { MaterialsModalComponent } from './materials-modal.component';
import { FormsModule } from '@angular/forms';
import { InputModalModule } from '../input-modal/input-modal.module';

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule, PipesModule, FormsModule, InputModalModule],
  declarations: [MaterialsModalComponent],
  exports: [MaterialsModalComponent]
})
export class MaterialsModalModule {}
