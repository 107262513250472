/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/naming-convention */
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthInterceptor } from './core/interceptors/auth-interceptor';
import { CoreModule } from './core/core.module';
import { BackgroundGeolocation } from '@ionic-native/background-geolocation/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import * as Sentry from '@sentry/capacitor';
import * as SentrySibling from '@sentry/angular';
import packageJson from '../../package.json';
import { Preferences } from '@capacitor/preferences';
import { PipesModule } from './pipes/pipes.module';

let clientUser: string;

Preferences.get({ key: 'clientUser' }).then((res) => {
  clientUser = JSON.parse(res.value);
});

// Enable Sentry in production environment
if (environment.production) {
  Sentry.init(
    {
      dsn: 'https://b71b70a78905ad91fbd4b647bfdd0bf4@o1278055.ingest.us.sentry.io/4507498159931392',
      // To set your release and dist versions
      release: `${packageJson.name} ${packageJson.version}`,
      dist: '1',
      environment: environment.production ? 'production' : 'develop',
      debug: environment.debug,
      // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
      // We recommend adjusting this value in production.
      tracesSampleRate: 1.0,
      integrations: [SentrySibling.browserTracingIntegration()],
      // Set "tracePropagationTargets" to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', 'https://dev-api.brainssoftware-apps.nl', 'https://api.brainssoftware-apps.nl'],
      beforeSend(event, hint) {
        if (event.exception) {
          event.extra = {
            ...event.extra,
            clientUser
          };
        }
        return event;
      }
    },
    // Forward the init method to the sibling Framework.
    SentrySibling.init
  );
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const interceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }
];

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    CoreModule,
    BrowserModule,
    IonicModule.forRoot({ mode: 'md' }),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    SharedModule,
    PipesModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      // Attach the Sentry ErrorHandler
      useValue: SentrySibling.createErrorHandler()
    },
    {
      provide: SentrySibling.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [SentrySibling.TraceService],
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    interceptorProviders,
    TranslatePipe,
    BackgroundGeolocation
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
