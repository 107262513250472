import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PAGES } from 'src/constants/pages';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { ExecutorGuard } from './core/guards/executor.guard';
import { PinGuard } from './core/guards/pin/pin.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: PAGES.rootPage,
    pathMatch: 'full'
  },
  {
    path: PAGES.loginPage,
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule)
  },
  {
    path: PAGES.pinPage,
    loadChildren: () => import('./pages/pin/pin.module').then((m) => m.PinPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: PAGES.passwordPage,
    loadChildren: () => import('./pages/password/password.module').then((m) => m.PasswordPageModule),
    canActivate: [AuthGuard, PinGuard]
  },
  {
    path: PAGES.activitiesPage,
    loadChildren: () => import('./pages/activities/activities.module').then((m) => m.ActivitiesPageModule),
    canActivate: [AuthGuard, ExecutorGuard, PinGuard]
  },
  {
    path: PAGES.assignmentsPage,
    loadChildren: () => import('./pages/assignments/assignments.module').then((m) => m.AssignmentsPageModule),
    canActivate: [AuthGuard, PinGuard]
  },
  {
    path: PAGES.projectsPage,
    loadChildren: () => import('./pages/projects/projects.module').then((m) => m.ProjectsPageModule),
    canActivate: [AuthGuard, ExecutorGuard, PinGuard]
  },
  {
    path: PAGES.settingsPage,
    loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsPageModule),
    canActivate: [AuthGuard, PinGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
