import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { STORAGE_KEYS } from 'src/constants/storage-keys';
import { environment } from 'src/environments/environment';
import { LoginForm } from '../../classes/auth/login-form';
import { LoginResponse } from '../../classes/auth/login-response';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Url for use in redirecting
  public redirectUrl: string;

  // Api reference
  private api = environment.api;

  // Constants
  private storageKeys = STORAGE_KEYS;

  // for auth
  private accessToken: string;

  constructor(private http: HttpClient, private storageService: StorageService) {}

  /**
   * Tries to get an access token from api.
   * @param loginForm Login credentials
   */
  public login(loginForm: LoginForm): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${this.api}/auth`, loginForm);
  }

  /**
   * Returns access token from property.
   */
  public getAccessToken(): string {
    return this.accessToken;
  }

  /**
   * Save the access token to local storage.
   * @param accessToken The token.
   */
  public saveAccessToken(accessToken: string): Promise<void> {
    this.accessToken = accessToken;
    return this.storageService.saveToStorage(this.storageKeys.accessToken, accessToken);
  }

  /**
   * Returns access token from storage.
   */
  public getAccessTokenFromStorage(): Promise<string> {
    return new Promise<string>((resolve) => {
      // Get token from storage
      this.storageService.getFromStorage(this.storageKeys.accessToken).then((accessToken) => {
        // Set token in property
        this.accessToken = accessToken;
        // Return token
        resolve(accessToken);
      });
    });
  }

  /**
   * Removes access token.
   */
  public removeAccessToken(): Promise<void> {
    delete this.accessToken;
    return this.storageService.removeFromStorage(this.storageKeys.accessToken);
  }
}
