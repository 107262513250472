<ion-app>
  <ion-menu
    (ionWillOpen)="menuWillOpen()"
    [ngStyle]="!showMenuBars(this.showMenuOnThesePages) && { display: 'none' }"
    contentId="main-content"
    type="overlay"
  >
    <ion-content>
      <ion-list id="menu-list" class="ion-no-padding">
        <div id="menu-header">
          <img *ngIf="theme?.logoUrl; else defaultLogo" [src]="theme.logoUrl | safeUrl" />
          <ng-template #defaultLogo>
            <img class="no-select" id="menu-logo" src="assets/icon/logo.png" />
          </ng-template>
        </div>

        <ion-menu-toggle auto-hide="false" *ngIf="user">
          <ion-item
            routerDirection="root"
            (click)="toAssignmentsPage()"
            lines="none"
            detail="false"
            [ngClass]="{ selected: selectedIndex === 2 }"
            class="menu-item pointer"
          >
            <ion-icon name="reader-outline" class="ion-margin-end"></ion-icon>
            <ion-label>{{ 'sideMenu.assignmentButton' | translate }}</ion-label>
          </ion-item>

          <ion-item
            *ngIf="user.role === userRole.executor"
            routerDirection="root"
            (click)="toActivitiesPage()"
            lines="none"
            detail="false"
            [ngClass]="{ selected: selectedIndex === 1 }"
            class="menu-item pointer"
          >
            <ion-icon name="list-outline" class="ion-margin-end"></ion-icon>
            <ion-label>{{ 'sideMenu.activitiesButton' | translate }}</ion-label>
          </ion-item>

          <ion-item
            *ngIf="user.role === userRole.executor"
            routerDirection="root"
            (click)="toProjectsPage()"
            lines="none"
            detail="false"
            [ngClass]="{ selected: selectedIndex === 0 }"
            class="menu-item pointer"
          >
            <ion-icon name="briefcase-outline" class="ion-margin-end"></ion-icon>
            <ion-label>{{ 'sideMenu.projectsButton' | translate }}</ion-label>
          </ion-item>

          <ion-item
            routerDirection="root"
            (click)="toSettingsPage()"
            lines="none"
            detail="false"
            [ngClass]="{ selected: selectedIndex === 3 }"
            class="menu-item pointer"
          >
            <ion-icon name="settings-outline" class="ion-margin-end"></ion-icon>
            <ion-label>{{ 'sideMenu.settingsButton' | translate }}</ion-label>
          </ion-item>

          <ion-item
            routerDirection="root"
            (click)="toLoginPage()"
            lines="none"
            detail="false"
            [ngClass]="{ selected: selectedIndex === 4 }"
            class="menu-item pointer"
          >
            <ion-icon name="log-out-outline" class="ion-margin-end"></ion-icon>
            <ion-label>{{ 'sideMenu.logoutButton' | translate }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-app>
